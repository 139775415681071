import { Button, Card, Center, Group, SimpleGrid, Text, Title, Transition } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ConnectionsTrivia } from './Trivia/connectionTrivia';
import { GeneralQuestionsTrivia } from './Trivia/GeneralQuestionsTrivia';
import { locationType, MapTrivia } from './Trivia/MapTrivia';
import { OrderingTrivia } from './Trivia/OrderingTrivia';

type dataType = {
    type: string;
    question?: string | string[];
    ordering?: any[];
    answer?: string[];
    location?: any;
    questions?: any[];
    title?: string;
    helper_text?: string;
    limit?: number;
}


type totalType = {
    [key: string]: number
}

export function Trivia() {
    const [currentSection, setCurrentSection] = useState(0)
    const [totalScore, setTotalScore] = useState<totalType>({ total: 0 })
    const [revealScore, setRevealScore] = useState(false)
    let [searchParams] = useSearchParams();
    let queryWeek = searchParams.get('week');

    const { data, isFetched } = useQuery({
        queryKey: ['triviaData'],
        queryFn: () =>
            fetch(`https://fzevqmieaebbfsbmdvrf.supabase.co/functions/v1/quiz?${queryWeek ? `week=${queryWeek}` : ''}`).then((res) =>
                res.json(),
            ),
    })

    const moveToNextSection = (score: number) => {
        const cummulativeScore = totalScore.total + score
        const newTotalScore: totalType = { ...totalScore, total: cummulativeScore }
        if (currentSection > 0) {
            newTotalScore[`section${currentSection}`] = score
        }
        setTotalScore(newTotalScore);
        setCurrentSection(currentSection + 1)
    }

    const sectionCard = (section: dataType) => {
        if (section.type === 'ordering') {
            return <OrderingTrivia
                question={section.question as string || ''}
                orderSectionOptions={section.ordering || []}
                categoryAcceptedAnswers={section.answer || []}
                finishSectionFn={moveToNextSection}
            />
        }
        if (section.type === 'map') {
            return <MapTrivia question={section.question as string || ""} location={section.location as locationType} finishSectionFn={moveToNextSection} />
        }
        if (section.type === 'general') {
            const questions = (section.questions as any[] || []).sort((a, b) => a.ordinal - b.ordinal)
            return <GeneralQuestionsTrivia questions={questions} finishSectionFn={moveToNextSection} helperText={section.helper_text} />
        }
        if (section.type === 'connection') {
            return <ConnectionsTrivia options={section.ordering || []} answer={section.answer || []} limit={section.limit} finishSectionFn={moveToNextSection} />
        }
        return null
    }


    const sections = data?.data[0]?.section || []
    const totalSections = sections.length
    const week = data?.data[0]?.week || ""

    return (
        <div>
            <Card shadow="sm" padding="lg" radius="xl" withBorder styles={{ root: { backgroundColor: '#FFCB64', minHeight: 'calc(100vh - 48px)' } }} m="12px">
                {currentSection === 0 && (
                    <>
                        <Card.Section inheritPadding={true} styles={{ section: { backgroundColor: '#ffaa00' } }}>

                            <Center>
                                <Title order={1} p="lg">
                                    Welcome to Twintig
                                </Title>
                            </Center>
                        </Card.Section>
                        <Card.Section inheritPadding={true}>
                            <Center>
                                <Text size='xl' p='md'>
                                    The game of 20 points
                                </Text>
                            </Center>
                            <Center>
                                <Text size='xl' p='md'>
                                    {week}
                                </Text>
                                {isFetched && !week && (<Text size='xl' p='md'>
                                    Oops, couldn't load
                                </Text>)}
                            </Center>
                            <Center>

                                <Button mt="md" size="xl" radius="lg" onClick={() => moveToNextSection(0)} disabled={totalSections === 0}>
                                    Begin
                                </Button>
                            </Center>
                        </Card.Section>
                    </>
                )}
                {currentSection > 0 && currentSection <= totalSections && (<>
                    <Card.Section styles={{ section: { backgroundColor: '#ffaa00' } }} inheritPadding={true}>

                        <Group justify="space-between" mt="md" p="md" mb="xs">
                            <Text size="lg" fw={500}>Section {currentSection}</Text>

                            <Text size="md">
                                {sections[currentSection - 1].title}
                            </Text>
                        </Group>
                    </Card.Section>
                    {sectionCard(sections[currentSection - 1])}
                </>)}
                {currentSection > totalSections && (
                    <>
                        <Card.Section inheritPadding={true} styles={{ section: { backgroundColor: '#ffaa00' } }}>

                            <Center>
                                <Title order={1} p="lg">
                                    Final Score
                                </Title>
                            </Center>
                        </Card.Section>
                        <Card.Section inheritPadding={true}>
                            {!revealScore && (<Center><Button size="xl" onClick={() => setRevealScore(true)}>Reveal</Button></Center>)}
                            {Object.entries(totalScore).sort((a, b) => a[0].localeCompare(b[0])).map((entry, index) => {
                                return (
                                    <SimpleGrid cols={3} key={entry[0]}>
                                        <div></div>
                                        <Group justify="space-between">
                                            <Transition
                                                mounted={revealScore}
                                                transition="slide-right"
                                                duration={400}
                                                enterDelay={index * 200}
                                            >
                                                {(styles) => <Text size="xl" style={styles}>{entry[0] === 'total' ? 'Total' : index + 1}</Text>}
                                            </Transition>
                                            <Transition
                                                mounted={revealScore}
                                                transition="slide-left"
                                                duration={400}
                                                enterDelay={index * 200}
                                            >
                                                {(styles) => <Text size="42px" fw="500" mb="sm" style={styles}>{entry[1]}</Text>}
                                            </Transition>
                                        </Group>
                                        <div></div>

                                    </SimpleGrid>
                                )
                            })}
                            {/* // <Center h={100}>
                            //     <Text size="xl" p="lg">
                            //         {totalScore.total}
                            //     </Text>
                            // </Center>
                            // <Center h={100}>
                            //     <Text size="xl" p="lg">
                            //         section1: {totalScore["section1"]}
                            //     </Text>
                            // </Center> */}
                        </Card.Section>
                    </>
                )}

                {/* <div>{currentSection}</div> */}
                {/* <Card radius="md" styles={{ root: { backgroundColor: '#FFCB64' } }}>Current Score: {score}</Card> */}
            </Card>
        </div >
    );
}
