import { Avatar, Button, Card, CardSection, Center, Flex, NativeSelect, Switch, Text, TextInput, Transition } from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useState } from 'react';

type questionType =
    { question: string; type: string; answer: string; options?: string[]; interestingFact?: string, correct?: boolean }

type Props = {
    questions: questionType[]
    finishSectionFn: (score: number) => void
    helperText?: string
}

export function GeneralQuestionsTrivia(
    { questions,
        finishSectionFn,
        helperText
    }: Props) {
    const [constructedQuestions, setConstructedQuestions] = useState<questionType[]>(questions)
    const [score, setScore] = useState<number | null>(null)

    const form = useForm({
        mode: 'uncontrolled',
    });

    const onSubmit = (values: any) => {
        let newScore = 0
        const newQuestions = questions.map((question, index) => {
            const value = values[`question-${index}`]
            let isCorrect = false;
            if (question.type === 'select' && value === question.answer) {
                isCorrect = true
            }
            else if (question.type === 'input' && value?.toLowerCase() === question.answer.toLowerCase()) {
                isCorrect = true
            }
            else if (question.type === 'switch' && ((question.answer === 'True' && value) || (question.answer === 'False' && !value))) {
                isCorrect = true
            }
            if (isCorrect) newScore++
            return { ...question, correct: isCorrect }
        })
        setConstructedQuestions(newQuestions)
        setScore(newScore)
    }

    const onSubmitFinal = () => {
        finishSectionFn(score || 0)
    }

    const hasScore = score !== null;

    return (
        <>
            <form onSubmit={form.onSubmit((values) => onSubmit(values))} >
                <Card.Section inheritPadding={true} styles={{
                    section: { marginTop: '24px', flexGrow: 1 },
                }}>
                    <Text size="lg">
                        {helperText}
                    </Text>

                    {constructedQuestions.map((question, index) =>
                    (<Flex align='end' mr="lg" styles={{ root: { flexGrow: 1 } }} key={question.question}>

                        {question.type === 'select' && question.options && (<NativeSelect size="lg" radius="lg" label={`${index + 1}. ${question.question}`} data={['', ...question.options]}
                            ml='lg' mr='lg'
                            mb={question.correct === false ? '' : 'lg'}
                            key={form.key(`question-${index}`)} {...form.getInputProps(`question-${index}`)}
                            error={question.correct === false ? question.answer : ''}
                            disabled={hasScore}
                            styles={{ root: { flexGrow: 1 } }} />
                        )}
                        {question.type === 'input' && (
                            <TextInput size="lg" radius="lg" label={`${index + 1}. ${question.question}`}
                                ml='lg' mr='lg'
                                mb={question.correct === false ? '' : 'lg'}
                                key={form.key(`question-${index}`)} {...form.getInputProps(`question-${index}`)}
                                error={question.correct === false ? question.answer : ''}
                                disabled={hasScore}
                                styles={{ root: { flexGrow: 1 } }} />
                        )}
                        {question.type === 'switch' && (
                            <Switch size="lg" label={question.question} m="lg" key={form.key(`question-${index}`)} {...form.getInputProps(`question-${index}`)} disabled={hasScore} styles={{ root: { flexGrow: 1 } }} />
                        )}
                        <Transition
                            mounted={hasScore && !!question.correct}
                            transition="slide-left"
                            duration={400}
                            timingFunction="ease"
                        >
                            {(styles) =>
                                <Avatar style={styles} src='check-circle.svg' mb='xl' />
                            }
                        </Transition>
                        <Transition
                            mounted={hasScore && !question.correct}
                            transition="slide-left"
                            duration={400}
                            timingFunction="ease"
                        >
                            {(styles) => <Avatar style={styles} src='x-circle.svg' mb='xl' />}
                        </Transition>
                    </Flex>)
                    )}


                </Card.Section>
                <CardSection inheritPadding={true} mb="xs">
                    <Center>
                        {!hasScore && (<Button size="lg" radius="md" type="submit">
                            Submit
                        </Button>)}
                        {hasScore && (<Button size="lg" radius="md" onClick={onSubmitFinal}>
                            Next Section
                        </Button>)}
                    </Center>
                </CardSection>
                {/* {score && <div>Score {score}</div>} */}
            </form>
        </>
    );
}
