import { useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";


const center = [44.952146, -93.191107];

export function Main() {
    const [mapClasses, setMapClasses] = useState("map-opacity-30")
    const [logoClasses, setLogoClasses] = useState("logo-container-start")
    const [logoImageClasses, setLogoImageClasses] = useState("logo-image-large")

    const onClickEvent = () => {
        setMapClasses("map-opacity-full");
        setLogoClasses("logo-container-end")
        setLogoImageClasses("logo-image-small")
    }

    return (<div>
        <div className={`logo-container ${logoClasses}`} onClick={onClickEvent}><img src='l_logo_map_2.jpeg' className={`logo-image logo-boxshadow ${logoImageClasses}`} alt='' /></div>
        <div className={`full-map ${mapClasses}`} >
            <MapContainer center={[center[0], center[1]]} zoom={12} className="full-height" scrollWheelZoom={false} dragging={false} zoomControl={false}>
                <TileLayer
                    attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> '
                    url="tiles/{z}/{x}/{y}{r}.png"
                />
            </MapContainer>
        </div>
    </div>)
}