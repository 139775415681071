import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
// import { Cities } from './Cities';
import { Main } from './Main';
import { Trivia } from './Trivia';

const queryClient = new QueryClient()

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Main />
      ),
    },
    // {
    //   path: "/cities",
    //   element: (
    //     <Cities />
    //   ),
    // },
    {
      path: "/trivia",
      element: (
        <Trivia />
      ),
    },
  ]);

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />
    </QueryClientProvider>
  );
}

export default App;
