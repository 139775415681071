import { Button, Card, CardSection, Center, Group, Text } from '@mantine/core';
import { useState } from 'react';


type itemType =
    { word: string; ordinal: number, correct?: boolean }

type Props = {
    options: itemType[]
    answer: string[]
    finishSectionFn: (score: number) => void
    limit?: number
    helperText?: string
}

type buttonType = { label: string, selected: boolean }

export function ConnectionsTrivia(
    { options,
        answer,
        limit,
        finishSectionFn,
        helperText
    }: Props) {
    const [constructedOptions, setConstructedOptions] = useState<buttonType[]>(options.sort((a, b) => a.ordinal - b.ordinal).map(o => { return { label: o.word, selected: false } }))
    const [score, setScore] = useState<number | null>(null)

    const onButtonClick = (option: buttonType) => {
        const numberSet = constructedOptions.reduce((setNumber, opt) => {
            return setNumber + (opt.selected ? 1 : 0)
        }, 0)
        if (!option.selected && limit && limit <= numberSet) {
            return
        }
        const newOptions = [...constructedOptions]
        const optionIndex = newOptions.findIndex((o) => o.label === option.label)
        newOptions[optionIndex] = { ...option, selected: !option.selected }
        setConstructedOptions(newOptions)
    }


    const onSubmit = () => {
        const newScore = answer.reduce((totalCount, value) => {
            if (constructedOptions.find((option) => option.label === value)?.selected) {
                return totalCount + 1
            }
            return totalCount
        }, 0)
        setScore(newScore)
    }

    const onSubmitFinal = () => {
        finishSectionFn(score || 0)
    }

    const hasScore = score !== null;


    return (
        <>
            <Card.Section inheritPadding={true} styles={{
                section: { marginTop: '24px', flexGrow: 1 },
            }}>
                <Text size="lg">
                    {helperText}
                </Text>

                <Center>
                    <Group justify="center" styles={{ root: { maxWidth: '400px' } }}>
                        {constructedOptions.map((option) => {
                            return (
                                <Center key={option.label}>
                                    <Button onClick={() => onButtonClick(option)} size="lg" variant={option.selected ? "filled" : "default"} p="xs" styles={{ root: { height: '100px', width: '100px' }, label: { whiteSpace: 'unset' } }}>
                                        {option.label}
                                    </Button>
                                </Center>
                            )
                        })}
                    </Group>
                </Center>


            </Card.Section>
            <CardSection inheritPadding={true} mb="xs">
                <Center>
                    {!hasScore && (<Button size="lg" radius="md" type="submit" onClick={onSubmit}>
                        Submit
                    </Button>)}
                    {hasScore && (<Button size="lg" radius="md" onClick={onSubmitFinal}>
                        Next Section
                    </Button>)}
                </Center>
            </CardSection>
        </>
    );
}
