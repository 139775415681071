import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, MantineColorsTuple, MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';


const myColor: MantineColorsTuple = [
  "#ffebed",
  "#fbd5d9",
  "#f1a9b0",
  "#e97a85",
  "#e25360",
  "#de3a49",
  "#dd2c3d",
  "#c41f2f",
  "#af1829",
  "#9a0a21"
];

const theme = createTheme({
  colors: {
    myColor,
  }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <MantineProvider theme={theme} >
      <App />
    </MantineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
