import { Avatar, Button, Card, CardSection, Center, Flex, Group, Mark, Paper, Text, TextInput, Transition } from '@mantine/core';
import { Reorder } from 'framer-motion';
import { useState } from 'react';

type itemType =
    { word: string; ordinal: number, correct?: boolean }

type Props = {
    orderSectionOptions: itemType[]
    categoryAcceptedAnswers: string[]
    question: string
    finishSectionFn: (score: number) => void
}

export function OrderingTrivia(
    { orderSectionOptions,
        categoryAcceptedAnswers,
        question,
        finishSectionFn
    }: Props) {
    const [items, setItems] = useState(orderSectionOptions)
    const [answer, setAnswer] = useState("")
    const [hasCorrectAnswer, setHasCorrectAnswer] = useState(false)
    const [score, setScore] = useState<number | null>(null)


    const onSubmit = () => {
        let newScore = 0
        const newItems: itemType[] = []
        items.forEach((item, index) => {
            let correct = false;
            if (index === item.ordinal - 1) {
                newScore++
                correct = true;
            }
            newItems.push({ ...item, correct })
        })
        setItems(newItems)

        const correctAnswer = categoryAcceptedAnswers.find(ans => { return answer.toLowerCase().includes(ans) })
        if (correctAnswer) {
            setHasCorrectAnswer(true)
            newScore++
        }

        setScore(newScore)
    }

    const onSubmitFinal = () => {
        finishSectionFn(score || 0)
    }

    const hasScore = score !== null;

    return (
        <>

            <Card.Section inheritPadding={true} styles={{
                section: { marginTop: '24px', flexGrow: 1 },
            }}>
                <Text size="lg">
                    {question}
                </Text>

                <Reorder.Group axis="y" values={items} onReorder={setItems} as="ol">
                    {items.map((item) => {
                        const setColor = hasScore ? item.correct ? 'green' : 'red' : 'black';
                        return (<Reorder.Item key={item.word} value={item} >
                            <Flex align='center'>
                                <Paper shadow="md" radius="lg" p="md" m="md" withBorder styles={{ root: { flexGrow: 1, color: setColor } }} >
                                    <Group justify="space-between">
                                        <Text size="lg" fw={700}>{item.word}</Text>
                                        <Avatar src='reorder.svg' radius='sm' size='sm' />
                                    </Group>
                                </Paper>
                                <Transition
                                    mounted={hasScore && !!item.correct}
                                    transition="slide-left"
                                    duration={400}
                                    timingFunction="ease"
                                >
                                    {(styles) =>
                                        <Avatar style={styles} src='check-circle.svg' />}
                                </Transition>
                                <Transition
                                    mounted={hasScore && !item.correct}
                                    transition="slide-left"
                                    duration={400}
                                    timingFunction="ease"
                                >
                                    {(styles) => <Avatar style={styles} src='x-circle.svg' />}
                                </Transition>
                            </Flex>
                        </Reorder.Item>)
                    }
                    )}
                </Reorder.Group>

                <Group justify="flex-start" mt="md" p="md" mb="xs">
                    <Text size="lg" >
                        What is the category?
                    </Text>
                    <Flex align='center' mr="lg" styles={{ root: { flexGrow: 1 } }}>

                        <TextInput placeholder="" size="lg" pr="lg" styles={{ root: { flexGrow: 1 } }} onChange={(event) => { setAnswer(event.target.value) }} disabled={hasScore} radius="lg" />
                        <Transition
                            mounted={hasScore && hasCorrectAnswer}
                            transition="slide-left"
                            duration={400}
                            timingFunction="ease"
                        >
                            {(styles) =>
                                <Avatar style={styles} src='check-circle.svg' />}
                        </Transition>
                        <Transition
                            mounted={hasScore && !hasCorrectAnswer}
                            transition="slide-left"
                            duration={400}
                            timingFunction="ease"
                        >
                            {(styles) => <Avatar style={styles} src='x-circle.svg' />}
                        </Transition>
                    </Flex>
                </Group>
            </Card.Section>
            <CardSection inheritPadding={true} mb="xs">
                <Center>

                    <Transition
                        mounted={hasScore}
                        transition="slide-up"
                        duration={400}
                        timingFunction="ease"
                    >
                        {(styles) => <Text size="lg" pt="md" style={styles}>Correct Answer <Mark color="blue">{categoryAcceptedAnswers[0].toUpperCase()}</Mark> - Score: <Mark color="blue">{score}</Mark></Text>}
                    </Transition>
                </Center>
                <Center>
                    <Button size="lg" radius="md" mt="md" onClick={hasScore ? onSubmitFinal : onSubmit} disabled={!answer}>
                        {hasScore ? 'Next Section' : 'Submit'}
                    </Button>
                </Center>
            </CardSection>
        </>
    );
}
