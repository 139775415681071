import { Button, Card, Center, Container, Mark, Text } from '@mantine/core';
import distance from '@turf/distance';
import { point } from "@turf/helpers";
import { LatLng } from 'leaflet';
import { useState } from 'react';
import { CircleMarker, MapContainer, Marker, TileLayer, useMapEvents, Polyline } from 'react-leaflet';

export type locationType =
    { lat: number; long: number, name: string }

type Props = {
    location: locationType
    question: string
    finishSectionFn: (score: number) => void
}

const center = [39.833333, -98.583333]

const LocationMarker = ({ onSubmit, set }: { onSubmit: (position: LatLng) => void, set: boolean }) => {
    const [position, setPosition] = useState<LatLng | null>(null)
    useMapEvents({
        click(e) {
            if (!set) {
                setPosition(e.latlng)
                onSubmit(e.latlng)
            }
        },
    })

    return position === null ? null : (
        <Marker position={position} />
    )
}

export function MapTrivia(
    { location,
        question,
        finishSectionFn
    }: Props) {
    const [markedLocation, setMarkedLocation] = useState<LatLng | null>(null)
    const [score, setScore] = useState<number | null>(null)
    const [markedDistance, setMarkedDistance] = useState<number | null>(null)

    const onSubmit = () => {
        let newScore = score || 0;
        if (markedLocation) {
            const answerPoint = point([location.long, location.lat])
            const submittedPoint = point([markedLocation.lng, markedLocation.lat])
            const calculatedDistance = distance(answerPoint, submittedPoint, { units: 'miles' })
            let pointsForHowClose = 0
            if (calculatedDistance <= 600) pointsForHowClose = 1
            if (calculatedDistance <= 500) pointsForHowClose = 2
            if (calculatedDistance <= 300) pointsForHowClose = 3
            if (calculatedDistance <= 200) pointsForHowClose = 4
            if (calculatedDistance <= 100) pointsForHowClose = 5
            newScore = newScore + pointsForHowClose
            setMarkedDistance(Math.round(calculatedDistance))
        }
        setScore(newScore)
    }

    const onSubmitFinal = () => {
        finishSectionFn(score || 0)
    }

    const hasScore = score !== null;

    return (
        <>
            <Card.Section inheritPadding={true}>
                <Text size="lg" pt="md">
                    {question}
                </Text>
            </Card.Section>

            <Card.Section inheritPadding={false} styles={{
                section: { marginTop: '24px', height: '100%', flexGrow: 1 },
            }}>
                <Container styles={{ root: { height: '600px' } }}>
                    <MapContainer center={[center[0], center[1]]} zoom={4} className="full-height" scrollWheelZoom={false} dragging={true} zoomControl={false}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> '
                            url="https://tiles.stadiamaps.com/tiles/stamen_watercolor/{z}/{x}/{y}.jpg"
                        />
                        <LocationMarker onSubmit={setMarkedLocation} set={!!score} />
                        {score != null && markedLocation && markedDistance && (
                            <>
                                <CircleMarker center={{ lat: location.lat, lng: location.long }} radius={3} />
                                <Polyline positions={[[location.lat, location.long], [markedLocation?.lat, markedLocation?.lng]]} />
                            </>
                        )}
                    </MapContainer>
                </Container>
            </Card.Section>

            <Card.Section inheritPadding={true} mb="xs">
                <Center>
                    {score && (<Text size="lg" pt="md">Distance <Mark color="blue">{markedDistance} miles</Mark> - Score: <Mark color="blue">{score}</Mark></Text>)}
                </Center>
                <Center>
                    <Button mt="md" radius="md" onClick={score !== null ? onSubmitFinal : onSubmit} disabled={!markedLocation}>
                        {hasScore ? 'Next Section' : 'Submit'}
                    </Button>
                </Center>
            </Card.Section>
        </>
    );
}
